import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/med-apps/apps/booking/src/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/nr/prog/doto/solutions/med-apps/apps/booking/src/providers/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/@mantine+core@7.13.3_@mantine+hooks@7.13.3_react@18.3.1__@types+react@18.3.11_react-dom@18.3._dwcfapxae2o6c6uvo2sc7tmpnm/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/@mantine+core@7.13.3_@mantine+hooks@7.13.3_react@18.3.1__@types+react@18.3.11_react-dom@18.3._dwcfapxae2o6c6uvo2sc7tmpnm/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/@mantine+dates@7.13.3_@mantine+core@7.13.3_@mantine+hooks@7.13.3_react@18.3.1__@types+react@1_y7rrvv3sjlnvfgtg7h6khisdbq/node_modules/@mantine/dates/styles.css");
;
import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/jotai-devtools@0.10.1_@types+react@18.3.11_react-dom@18.3.1_react@18.3.1__react@18.3.1_redux@5.0.1/node_modules/jotai-devtools/dist/index.css");
